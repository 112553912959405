module.exports = {
  SERIES_CURVAS: '/seriescurvas',
  LIST_CURVAS: '/listCurvas',
  LIST_PLD: '/listPLD',
  DISTRIBUIDORAS: '/distribuidoras',
  LIST_DISTRIBUIDORAS_BY_USER: '/listDistribuidorasByUser',
  LIST_GROUPED_DISTRIBUIDORAS_BY_USER: '/listDistribuidorasByUserGrouped',
  TARIFAS: '/tarifas',
  FWD_AHEAD: '/listAverageFWDAhead'
};
