import { i18n } from '../main';

export default {
  selected: null,
  options: [
    {
      value: null,
      text: i18n.tc('regulated_market.select_voltage_class'),
      disabled: true
    },
    { value: 'A2', text: 'A2' },
    { value: 'A3', text: 'A3' },
    { value: 'A3A', text: 'A3a' },
    { value: 'A4', text: 'A4' }
  ]
};
