import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import axios from 'axios';
import dayjs from 'dayjs';
import Vue from 'vue';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

import { i18n } from '../../main';

import { getAuth } from '../auth/auth.helpers';
import {
  DISTRIBUIDORAS,
  LIST_DISTRIBUIDORAS_BY_USER,
  LIST_GROUPED_DISTRIBUIDORAS_BY_USER,
  TARIFAS
} from '../../utils/endPoints';

Vue.use(VueToast);
dayjs.locale('pt-br');

const CONFIG = new Config();

function uniq(arr, param) {
  return arr.filter(function (item, pos, array) {
    return (
      array
        .map(function (mapItem) {
          return mapItem[param];
        })
        .indexOf(item[param]) === pos
    );
  });
}

export const getDistributors = async () => {
  var data = JSON.stringify({ numeroResolucoes: 5 });
  const { token } = getAuth();

  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}${DISTRIBUIDORAS}${LIST_DISTRIBUIDORAS_BY_USER}`,
    headers: {
      Bearer: token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      return uniq(response.data, 'cnpjDistribuidora');
    })
    .catch(function (error) {
      return Vue.$toast.error(error, {
        position: 'top-right',
        duration: 5000
      });
    });
};

export function extractDistributorFromTarrifs(tarifas) {
  const distribuidorasList = tarifas.map((item) => {
    return {
      text: item.distribuidora.nomeDistribuidora,
      value: item.distribuidora
    };
  });

  distribuidorasList.sort(function (a, b) {
    return a.text < b.text ? -1 : 1;
  });

  return distribuidorasList;
}

export const geGroupedDistributors = async () => {
  var data = JSON.stringify({ numeroResolucoes: 5 });
  const { token } = getAuth();

  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}${DISTRIBUIDORAS}${LIST_GROUPED_DISTRIBUIDORAS_BY_USER}`,
    headers: {
      Bearer: token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return Vue.$toast.error(error, {
        position: 'top-right',
        duration: 5000
      });
    });
};

export const getTariff = async () => {
  var data = JSON.stringify({ numeroResolucoes: 5 });
  const { token } = getAuth();

  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}${DISTRIBUIDORAS}${TARIFAS}`,
    headers: {
      Bearer: token,
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Vue.$toast.error(error, {
        position: 'top-right',
        duration: 5000
      });
    });
};

//Método que busca todas as tarifas e filtra/mapeia por distribuidoras que o usuario tem acesso
export const doSearch = async ({ horosazonal, tensao }) => {
  const distributors = await getDistributors();
  const tariffs = await getTariff();

  return distributors.map((distributor) => {
    const tariffsFromDistributor = tariffs.filter(
      (item) =>
        item.distribuidora.cnpjDistribuidora === distributor.cnpjDistribuidora
    );

    const resolucoes = tariffsFromDistributor.map((resolucao) => {
      return resolucao.resolucoes;
    });

    const itens = resolucoes.flat(Infinity).map((item) => {
      return item.itens;
    });

    const date = resolucoes.flat(Infinity).map((item) => {
      return dayjs(item.dataResolucao).valueOf();
    });

    const itensFiltered = itens.flat(Infinity).filter((item) => {
      return (
        item.tarifaHorosazonal === horosazonal && item.classeTensao === tensao
      );
    });

    const data = [
      {
        itens: itensFiltered,
        date
      }
    ];

    return {
      name: distributor.nomeDistribuidora,
      cnpj: distributor.cnpjDistribuidora,
      data
    };
  });
};

//Método que busca todas as tarifas e filtra/mapeia por distribuidoras que o usuario tem acesso
export function filtraTarifasFromParameters(
  tariffs,
  distributor,
  horosazonal,
  tensao
) {
  //Filtra as tarifas da distribuidora, usando o cnpj
  const tariffsFromDistributor = tariffs.filter(
    (item) =>
      item.distribuidora.cnpjDistribuidora === distributor.cnpjDistribuidora
  );

  const resolucoes = tariffsFromDistributor.map((resolucao) => {
    return resolucao.resolucoes;
  });

  const itens = resolucoes.flat(Infinity).map((item) => {
    return item.itens;
  });

  const date = resolucoes.flat(Infinity).map((item) => {
    return dayjs(item.dataResolucao).valueOf();
  });

  const itensFiltered = itens.flat(Infinity).filter((item) => {
    return (
      item.tarifaHorosazonal === horosazonal && item.classeTensao === tensao
    );
  });

  const data = [
    {
      itens: itensFiltered,
      date
    }
  ];

  return {
    name: distributor.nomeDistribuidora,
    cnpj: distributor.cnpjDistribuidora,
    tarifaHorosazonal: horosazonal,
    classeTensao: tensao,
    data
  };
}

export const groupedFilteredSearch = async () => {
  const distributors = await geGroupedDistributors();
  const tariffs = await getTariff();

  return distributors.map((distributor) => {
    const tariffsFromDistributor = tariffs.filter(
      (item) =>
        item.distribuidora.cnpjDistribuidora ===
        distributor.distribuidoraTHClasse.distribuidora.cnpjDistribuidora
    );

    const resolucoes = tariffsFromDistributor.map((resolucao) => {
      return resolucao.resolucoes;
    });

    const itens = resolucoes.flat(Infinity).map((item) => {
      return item.itens;
    });

    const date = resolucoes.flat(Infinity).map((item) => {
      return dayjs(item.dataResolucao).valueOf();
    });

    const itensFiltered = itens.flat(Infinity).filter((item) => {
      return (
        item.tarifaHorosazonal ===
          distributor.distribuidoraTHClasse.tarifaHorosazonal &&
        item.classeTensao === distributor.distribuidoraTHClasse.classeTensao
      );
    });

    const data = [
      {
        itens: itensFiltered,
        date
      }
    ];

    return {
      name: distributor.distribuidoraTHClasse.distribuidora.nomeDistribuidora,
      cnpj: distributor.distribuidoraTHClasse.distribuidora.cnpjDistribuidora,
      tarifaHorosazonal: distributor.distribuidoraTHClasse.tarifaHorosazonal,
      classeTensao: distributor.distribuidoraTHClasse.classeTensao,
      unidades: distributor.unidades,
      data
    };
  });
};

export function computeQuadro(item) {
  const itens = item.data.map((itemItens) => itemItens.itens);
  const date = item.data.map((itemDate) => itemDate.date);

  const isContent = itens
    .flat(1)
    .map((itemIsContent) => itemIsContent.tarifaEncargosMedia);

  var valor;
  if (isContent.length <= 0) {
    valor = false;
  }

  if (isContent.length > 0) {
    valor = true;
  }

  const qtdItens = itens.flat(1).length;

  let medium = [];
  let mediumForaPonta = [];

  let tusdPonta = [];
  let tusdFora = [];

  for (let i = 0; i < qtdItens; i++) {
    medium.push(
      [
        Number(itens.flat(1)[i].tarifaEncargosMedia.toFixed(2)),
        dayjs(date.flat(1)[i]).format('YYYY')
      ].reverse()
    );

    mediumForaPonta.push(
      [
        Number(itens.flat(1)[i].tarifaTusdEncargosForaPonta.toFixed(2)),
        dayjs(date.flat(1)[i]).format('YYYY')
      ].reverse()
    );

    tusdPonta.push(
      [
        Number(itens.flat(1)[i].tarifaTusdPonta),
        dayjs(date.flat(1)[i]).format('YYYY')
      ].reverse()
    );

    tusdFora.push(
      [
        Number(itens.flat(1)[i].tarifaTusdForaPonta),
        dayjs(date.flat(1)[i]).format('YYYY')
      ].reverse()
    );
  }

  return {
    temValor: valor,
    name: item.name,
    cnpj: item.cnpj,
    tarifaHorosazonal: item.tarifaHorosazonal,
    classeTensao: item.classeTensao,
    unidades: item.unidades,
    componentKey: item.cnpj + item.tarifaHorosazonal + item.classeTensao,
    medium: [
      {
        name: 'TE ' + i18n.tc('regulated_market.average'),
        data: medium.reverse()
      },
      {
        name: 'TUSD ' + i18n.tc('regulated_market.off_peak_charges'),
        data: mediumForaPonta.reverse()
      }
    ],
    tusd: [
      {
        name: i18n.tc('regulated_market.tip'),
        data: tusdPonta.reverse()
      },
      {
        name: i18n.tc('regulated_market.off_the_tip'),
        data: tusdFora.reverse()
      }
    ]
  };
}
