import { i18n } from '../main';

export default {
  selected: null,
  options: [
    {
      value: null,
      text: i18n.tc('regulated_market.select_horosazonal'),
      disabled: true
    },
    { value: 'VERDE', text: i18n.tc('regulated_market.green') },
    {
      value: 'VERDE_APE',
      text: i18n.tc('regulated_market.green_ape')
    },
    { value: 'AZUL', text: i18n.tc('regulated_market.blue') },
    { value: 'AZUL_APE', text: i18n.tc('regulated_market.blue_ape') }
  ]
};
